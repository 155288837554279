/* global toastr */

import { request } from '../lib/utils'
import AttrInput from '../lib/attr_input'
import React from 'react'
import { store } from '../../index'
import { BaseAdminView, stateToProps } from './base_view'
import { split_list, wrapView } from '../lib/utils'
import { Link } from 'react-router-dom'


export class BaseAdminModelView extends BaseAdminView {
  constructor(props) {
    super(props)

    this.state = {
      id: undefined,
      title: undefined,
      form_data_initial: undefined,
      form_data: undefined,
      form_access: undefined,
      form_messages: undefined,
    }

    this.formChange = this.formChange.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.saveObject = this.saveObject.bind(this)
    this.deleteObject = this.deleteObject.bind(this)
    this.dataToState = this.dataToState.bind(this)
  }

  get object_id() {
    const object_id = this.props.router_params.object_id
    return object_id === "new" ? 0 : parseInt(object_id)
  }

  get model_name() {
    return this.props.router_params.model_name
  }

  // Сохранить данные, пришедшие с back в state
  dataToState(data) {
    let state_data = { ...data }
    state_data.form_data_initial = data.form_data

    if (this.object_id === 0) {
      state_data.redirect_to = `/admin/${this.model_name}/${data.id}`
    }

    this.setState(state_data)
  }

  onSearch(data) {
    store.dispatch({ type: 'admin_search', data })
    this.redirect_to(`/admin/${this.model_name}`)
  }

  formChange(name, value) {
    this.setState({ form_data: { ...this.state.form_data, [name]: value } });
  }

  saveObject() {
    const that = this

    request({
      method: 'post',
      url: `/api/admin/${this.model_name}/${this.object_id}/update_object`,
      data: {
        "new_data": this.state.form_data,
        "old_data": this.state.form_data_initial,
      },
      success: (data) => {

        store.dispatch({ type: 'admin_list_clean' })
        if (data.form_data) {
          that.dataToState(data)
          toastr.success("Изменения сохранены")

          if(data.message) {
            toastr.success(data.message)
          }
        }

        if (data.errors) {
          that.setState({ form_errors: data.errors })
          toastr.warning("Изменения не были сохранены. Нужно исправить ошибки в форме документа", "Внимание")
        }
      },
      error: (data) => {
        toastr.error("Ошибка сохранения", data)
      }
    })
  }

  deleteObject() {
    const that = this

    request({
      method: 'post',
      url: `/api/admin/${this.model_name}/${this.object_id}/delete_object`,
      success: (data) => {
        store.dispatch({ type: 'admin_list_clean' })
        that.redirect_to(`/admin/${this.model_name}`)
        toastr.success("Успешно удалено")
      },
      error: (data) => {
        toastr.error(data.detail, "Ошибка удаления")
      }
    })
  }

  renderBreadcrumbs() {
    return (
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Домой</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/admin">Админка</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/admin/${this.model_name}`}>{this.props.model_title}</Link>
          </li>
          <li className="breadcrumb-item active">{this.state.title}</li>
        </ol>
      </nav>
    )
  }

  renderContentLeftAction() {
    return (
      <div className="card mb-3" key="actions">
        <div className="card-body p-2">
          <span className="btn btn-outline-primary waves-effect p-2 me-2" onClick={this.saveObject}>
            <span className="ti ti-xs ti-check me-1"></span>
            Сохранить
          </span>
          {this.state.perms.includes("delete") && <button className="btn btn-outline-danger waves-effect p-2 me-2" onClick={this.deleteObject}>
            <span className="ti ti-xs ti-trash me-1"></span>
            Удалить</button>}
        </div>
      </div>
    )
  }

  renderContentLeft() {
    const view = this;
    const data = this.state.form_data
    const attrs = this.props.attrs
    const form_access = this.state.form_access
    const form_access_names = Object.keys(form_access)

    function renderAttr(attr, horizontal) {
      const access = form_access[attr.name]
      const attr_messages = view.state.form_messages[attr.name]
      const attr_error = view.state.form_errors && view.state.form_errors[attr.name]

      if (!access) {
        return null
      }

      return <AttrInput key={attr.name}
        data_type={attr.data_type}
        choice_name={attr.choice_name}
        choices={view.props.choices}
        name={attr.name}
        title={attr.title}
        message={attr_messages}
        error={attr_error}
        value={data[attr.name]}
        access={access}
        inner_attrs={attr.inner_attrs}
        horizontal={horizontal || false}
        onChange={view.formChange} />
    }

    function renderGroup(item, idx) {
      let group_title = item[0]
      let attr_names = item[1]

      let wide_attrs = []
      let short_attrs = []

      const attr_names_allowed = attr_names.filter(value => form_access_names.includes(value))

      // В этой группе нет полей, для которых есть доступ
      if (attr_names_allowed.length === 0) {
        return null
      }

      attr_names_allowed.forEach(attr_name => {
        const attr = attrs[attr_name]
        if (attr.data_type === "table" || attr.tags.includes("form_wide"))
          wide_attrs.push(attr)
        else
          short_attrs.push(attr)

      });

      return (
        <div className="card mb-3 form-sm" key={idx}>
          <div className="card-body p-3">
            <h5 className="card-title">{group_title}</h5>
            <div className="card-text">
              <div className="row">
                {split_list(short_attrs, 2).map((attrs, idx) => {
                  return [
                    <div className="col-md-6" key={idx}>
                      {attrs.map((attr) => { return renderAttr(attr, true) })}
                    </div>
                  ]
                })}
                {wide_attrs && <div className="col-md-12" key={idx}>
                  {wide_attrs.map((attr) => { return renderAttr(attr, false) })}
                </div>}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return <div>
      {this.renderContentLeftAction()}
      {view.props.form_schema.map(renderGroup)}
    </div>
  }

  renderContentRight() {
    return null
  }

  waitRenderReady() {
    let view = this

    const dummyContent = super.waitRenderReady()
    if (dummyContent !== undefined) {
      return dummyContent
    }

    // Пока мета-данные текущей модели не пришли, выходим
    if (this.props.model_name !== this.model_name) {
      this.waitRenderReadyLock = true
      this.initMeta()
      return <div>Загрузка информации о модели</div>
    }

    if (this.state.id !== this.object_id) {
      this.waitRenderReadyLock = true
      request({
        method: 'get',
        url: `/api/admin/${this.model_name}/${this.object_id}/get_object`,
        success: (data) => {
          this.waitRenderReadyLock = false
          view.dataToState(data)
        },
      })
      return <div>Загрузка объекта</div>
    }
  }

  renderContent() {
    return <div className="row">
      <div className="col-md-8">
        {this.renderContentLeft()}
      </div>
      <div className="col-md-4">
        {this.renderContentRight()}
      </div>
    </div>
  }
}

export default wrapView(BaseAdminModelView, stateToProps)
