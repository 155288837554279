/**
 * Админка для редактирования пользователя
 */

/* global toastr */

import { BaseAdminModelView } from '../admin/model_view'
import { stateToProps } from '../admin/base_view'
import { request, wrapView } from '../lib/utils'
import AttrInput from '../lib/attr_input'



class UserAdminModelView extends BaseAdminModelView {
  constructor(props) {
    super(props)
    this.changePassword = this.changePassword.bind(this)
  }

  get model_name() {
    return "User"
  }

  changePassword() {
    const that = this
    request({
      method: 'post',
      url: '/api/admin/user_change_password',
      data: {
        user_id: this.object_id,
        value: this.state.change_password_value,
      },
      success: (message) => {
        toastr.info(message)
        that.setState({ change_password_value: "" })
      },
      error: (data) => {
        toastr.error(data.detail)
      },
    })
  }

  renderContentRight() {
    if (this.object_id === 0) {
      return null
    }

    if (this.state.perms.includes("change_password")) {
      const that = this
      const new_value = this.state.change_password_value || "";

      return (
        <div className="card mb-3">
          <div className="card-body p-3 pb-2">
            <h5 className="card-title">Поменять пароль</h5>
            <div className="card-text">
              <div className="mb-2">
                <AttrInput
                  data_type="string"
                  name="password"
                  placeholder="Новый пароль"
                  onChange={(name, value) => { that.setState({ change_password_value: value }) }}
                  value={new_value}
                />
              </div>

              <button className="btn btn-primary p-2 me-2" onClick={this.changePassword}>
                <i className="ti ti-sm ti-key me-2"></i>
                Поменять
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default wrapView(UserAdminModelView, stateToProps)
